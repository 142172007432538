export const buttonProps = {
  color: "var(--fg)",
  size: "1.5em",
  strokeWidth: 2,
}

export const playerButtonProps = {
  ...buttonProps,
  "padding-top": "3px",
}
