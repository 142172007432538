//import React from "react"
//import { colors } from "./Style"
import chroma from "chroma-js"
import { StylesConfig } from "react-select"
import { SongTag } from "../types/song"
import { globalTransition } from "../styles/Style"

// Colors for react-select
//https://react-select.com/styles#overriding-the-theme

export const searchbarColors = {
  primary: "var(--primary)",
  primary75: "var(--primary75)",
  primary50: "var(--primary50)",
  primary25: "var(--primary25)", // bg of hovered option
  danger: "var(--danger)",
  dangerLight: "var(--dangerLight)",
  neutral0: "var(--neutral0)",
  neutral5: "var(--neutral5)",
  neutral10: "var(--neutral10)",
  neutral20: "var(--neutral20)",
  neutral30: "var(--neutral30)",
  neutral40: "var(--neutral40)",
  neutral50: "var(--neutral50)",
  neutral60: "var(--neutral60)",
  neutral70: "var(--neutral70)",
  neutral80: "var(--neutral80)",
  neutral90: "var(--neutral90)",
}
export const customStyles: StylesConfig<SongTag> = {
  control: (provided) => {
    return {
      ...provided,
      transition: `background-color ${globalTransition}, border-color ${globalTransition}`,
    }
  },

  indicatorSeparator: (provided) => {
    return {
      ...provided,
      transition: `all ${globalTransition}`,
    }
  },

  placeholder: (provided) => {
    return {
      ...provided,
      transition: `all ${globalTransition}`,
    }
  },

  input: (provided) => {
    return {
      ...provided,
      gridTemplateColumns: "0 1fr",
    }
  },

  //menu: (provided) => ({
  //  ...provided,
  //  position: "relative",
  //}),

  multiValue: (provided, { data }) => {
    const accentColor = data.color
      ? chroma(data.color).alpha(0.5).css()
      : "var(--primary)"
    return {
      ...provided,
      backgroundColor: accentColor,
    }
  },

  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: "var(--fg)",
    }
  },

  multiValueRemove: (provided, { data }) => {
    const accentColor = data.hasOwnProperty("color")
      ? data.color
      : "var(--primary)"
    return {
      ...provided,
      color: accentColor,
      ":hover": {
        color: "var(--bg)",
        backgroundColor: accentColor,
      },
    }
  },
}
