import React from "react"
import Layout from "./src/components/Layout/Layout"
import { SearchProvider } from "./src/components/Search/SearchContext"
import type { GatsbyBrowser } from "gatsby"
//const {
//	updateNotificationState,
//} = require("./src/components/Layout/UpdateNotification")

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Wrap all pages with the Layout component - persistent across navigations
export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => <Layout path={props.path}>{element}</Layout>

// Providers should appaently be here
export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => <SearchProvider>{element}</SearchProvider>

//exports.onServiceWorkerUpdateFound = () => updateNotificationState.show()
