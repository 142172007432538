import { createGlobalStyle } from "styled-components"
import chroma from "chroma-js"
import { DefaultTheme } from "styled-components"
import { SnapsenColorConf, SnapsenTagLabelMap } from "../types/snapsenConfig"
import slugify from "underscore.string/slugify"

export const type = {
  baseFontSize: "16px",
  baseLineHeight: 1.4,
  headerFontFamily: ["Raleway", "serif"],
  bodyFontFamily: ["Raleway", "serif"],
  scaleRatio: 2,
  googleFonts: [
    {
      name: "Raleway",
      styles: ["400", "400i", "500", "500i", "700", "700i"],
    },
  ],
}

const generateTypeColors = (
  typeLabels: SnapsenTagLabelMap[],
  darkMode: boolean
) => {
  const key = darkMode ? "darkColor" : "color"
  const typeColors = typeLabels.map((typeLabel) => {
    return `--${slugify(typeLabel.value)}: ${typeLabel[key]};`
  })
  return `
  ${typeColors.join("\n")}
  `
}

const generateColors = (
  colors: SnapsenColorConf,
  filter: string,
  darkMode: boolean
) => {
  const coefficient = colors.shadeFactor ? colors.shadeFactor : 1
  // TODO: Fix 'any' types
  // Make chroma color more intense, depending on color mode
  function shade(color: any, factor: number) {
    return darkMode
      ? color.brighten(factor * coefficient).hex()
      : color.darken(factor * coefficient).hex()
  }

  // Make chroma color less intense, depending on color mode
  function fade(color: any, factor: number) {
    return darkMode
      ? color.darken(factor * coefficient).hex()
      : color.brighten(factor * coefficient).hex()
  }

  return `
    --bg: ${colors.bg};
    --fg: ${colors.fg};
    --light: ${darkMode ? colors.fg : colors.bg};
    --dark: ${darkMode ? colors.bg : colors.fg};
    --textLink: ${shade(chroma(colors.accent1).saturate(1), 0.1)};
    --fgFilter: ${filter};
    --primary: ${colors.primary}; // border of focused input
    --primary75: ${fade(chroma(colors.primary), 0.05)};
    --primary50: ${fade(chroma(colors.primary), 0.1)};
    --primary25: ${fade(chroma(colors.primary), 0.2)}; // bg of hovered option
    --danger: ${colors.alert};
    --dangerLight: ${fade(chroma(colors.alert), 2.0)};
    --neutral0: ${colors.bg};
    --neutral5: ${shade(chroma(colors.bg), 0.05)};
    --neutral10: ${shade(chroma(colors.neutral), 0.1)};
    --neutral20: ${shade(chroma(colors.neutral), 0.2)};
    --neutral30: ${shade(chroma(colors.neutral), 0.3)};
    --neutral40: ${shade(chroma(colors.neutral), 0.4)};
    --neutral50: ${shade(chroma(colors.neutral), 0.5)};
    --neutral60: ${shade(chroma(colors.neutral), 0.6)};
    --neutral70: ${shade(chroma(colors.neutral), 0.7)};
    --neutral80: ${shade(chroma(colors.neutral), 0.8)};
    --neutral90: ${shade(chroma(colors.neutral), 0.9)};
  `
}

export const globalTransition = "200ms linear"
export const GlobalStyle = createGlobalStyle<DefaultTheme>`
  body {
    ${(props) =>
      generateColors(props.colorsLight, props.colorsLightFilters.fg, false)}
    ${(props) => generateTypeColors(props.typeLabels, false)}
    background-color: var(--dark);
    color: var(--fg);
    transition: all ${globalTransition};
  }

  body.dark {
    -webkit-font-smoothing: antialiased;
    ${(props) =>
      generateColors(props.colorsDark, props.colorsDarkFilters.fg, true)}
    ${(props) => generateTypeColors(props.typeLabels, true)}
  }

  a {
    text-decoration: none;
    color: var(--textLink);
    transition: all ${globalTransition};
  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .container-fluid > div:first-child {
    margin-left: 0;
    margin-right: 0;
  }

  .container-fluid > div:first-child > div:first-child {
    padding-left: 0;
    padding-right: 0;
  }

  .hidden {
    display: none;
  }

  .screenreader-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .annotation {
    font-style: italic;
    float: left;
    display: inline;
    padding-right: 0.5em;
  }
  .slick-current {
    font-weight: 700;
  }
  .slick-slider {
    margin: 0 2em;
  }
  .slick-prev {
    color: var(--primary) !important;
    :before {
      color: var(--primary) !important;
    }
  }
  .slick-next {
    color: var(--primary) !important;
    :before {
      color: var(--primary) !important;
    }
  }
  .slick-dots li button:before {
    color: var(--neutral40) !important;
  }

  hr {
    background-color: var(--fg);
    transition: all ${globalTransition};
  }

  .Collapsible__trigger {
    display: block;
    position: relative;
    margin-bottom: 0.5em;
    padding-left: 1rem;
    border-top: 1px solid var(--fg);
    text-transform: capitalize;
    font-weight: 700;
    font-size: 1.2rem;
    transition: border-top ${globalTransition};

    &:after {
      content: 'V';
      font-weight: bold;
      position: absolute;
      right: 10px;
      bottom: 0px;
      display: block;
      transition: transform 300ms;
    }

    &.is-open {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  }

  .slick-prev:before, .slick-next:before {
    color: var(--fg) !important;
  }

  .rhap_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: inherit;
    width: 100%;
    //padding: 10px 15px;
    // background-color: var(--bg);

    &:focus:not(:focus-visible) {
      outline: 0;
    }

    svg {
      vertical-align: initial; // overwrite Bootstrap default
    }
  }

  .rhap_header {
    margin-bottom: 10px;
  }

  .rhap_footer {
    margin-top: 5px;
  }

  .rhap_main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .rhap_stacked {
    .rhap_controls-section {
      margin-top: 8px;
    }
  }

  .rhap_horizontal {
    flex-direction: row;

    .rhap_controls-section {
      margin-left: 8px;
    }
  }

  .rhap_horizontal-reverse {
    flex-direction: row-reverse;

    .rhap_controls-section {
      margin-right: 8px;
    }
  }

  .rhap_stacked-reverse {
    flex-direction: column-reverse;

    .rhap_controls-section {
      margin-bottom: 8px;
    }
  }

  .rhap_progress-section {
    display: flex;
    flex: 3 1 auto;
    align-items: center;
  }

  .rhap_progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1 0 auto;
    align-self: center;
    margin: 0 calc(10px + 1%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;

    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .rhap_time {
    //color: var(--fg);
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
  }

  .rhap_progress-bar {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 5px;
    background-color: var(--primary);
    border-radius: 2px;
  }

  .rhap_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: var(--fg);
    border-radius: 2px;
  }

  .rhap_progress-bar-show-download {
    background-color: var(--primary);
  }

  .rhap_download-progress {
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: var(--primary);
    border-radius: 2px;
  }

  .rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    top: -8px;
    background: var(--fg);
    border-radius: 50px;
  }

  .rhap_controls-section {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
  }

  .rhap_additional-controls {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
  }

  .rhap_repeat-button {
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: var(--fg);
    margin-right: 6px;
  }

  .rhap_main-controls {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rhap_main-controls-button {
    margin: 0 3px;
    color: var(--fg);
    font-size: 35px;
    width: 35px;
    height: 35px;
  }

  .rhap_play-pause-button {
    font-size: 40px;
    width: 40px;
    height: 40px;
    color: var(--fg);
  }

  .rhap_volume-controls {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;
  }

  .rhap_volume-button {
    flex: 0 0 26px;
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: var(--fg);
    margin-right: 6px;
  }

  .rhap_volume-container {
    display: flex;
    align-items: center;
    flex: 0 1 100px;
    user-select: none;
    -webkit-user-select: none;
  }

  .rhap_volume-bar-area {
    display: flex;
    align-items: center;
    width: 100%;
    height: 14px;
    cursor: pointer;

    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .rhap_volume-bar {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 4px;
    background: var(--primary);
    border-radius: 2px;
  }

  .rhap_volume-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    margin-left: -6px;
    left: 0;
    top: -4px;
    background: var(--fg);
    opacity: 0.9;
    border-radius: 50px;
    cursor: pointer;

    &:hover {
      opacity: .9;
    }
  }

  .rhap_volume-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: var(--fg);
    border-radius: 2px;
  }

  /* Utils */
  .rhap_button-clear {
    background-color: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      opacity: .9;
      transition-duration: .2s;
    }

    &:active {
      opacity: .95;
    }

    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

`

export default { GlobalStyle, globalTransition, type }
