import React from "react"
import styled from "styled-components"
import { globalTransition } from "../../styles/Style"

const Wrapper = styled.footer`
  padding: 0.5em 0;
  margin: 0 1em;
  text-align: right;
  color: var(--fg);
  background-color: var(--bg);
  transition: all ${globalTransition};
  border-top: 1px solid var(--fg);

  @media only screen and (min-width: 64em) {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
`

const formUrl =
  "https://docs.google.com/forms/d/e/1FAIpQLSfZ2W3a7rgDaifrzwXqGwW2Kzx-iHZQ6wXCXSP5H_dScWRHrw/viewform?usp=sf_link"

const Footer: React.FunctionComponent = () => (
  <Wrapper>
    Har du kommentarer om den digitala sångboken?{" "}
    <a href={formUrl}>Fyll i feedbackformuläret!</a>
  </Wrapper>
)

export default Footer
