/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import Wrapper from "./Wrapper"
import Header from "./Header"
import { GlobalStyle } from "../../styles/Style"
import styled from "styled-components"
import Footer from "./Footer"
import type { SnapsenMeta, SnapsenTag } from "../../types/snapsenConfig"
import { globalTransition } from "../../styles/Style"

const InnerBg = styled(Wrapper)`
  display: flex;
  flex-flow: column;
  background-color: var(--bg);
  min-height: 100vh;
  transition: all ${globalTransition};
`
const StyledHeader = styled(Header)`
  flex: none;
`
const Main = styled.main`
  flex: 1 0;
  margin: 0rem 1rem 1rem;
`
const StyledFooter = styled(Footer)`
  flex: none;
`

interface LayoutProps {
  children: React.ReactNode
  path: string
}

const Layout: React.FC<LayoutProps> = ({ children, path }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            colorsLight {
              fg
              bg
              primary
              alert
              accent1
              accent2
              neutral
              shadeFactor
            }
            colorsLightFilters {
              fg
            }
            colorsDark {
              fg
              bg
              primary
              alert
              accent1
              accent2
              neutral
              shadeFactor
            }
            colorsDarkFilters {
              fg
            }
            tags {
              labelMap {
                color
                darkColor
                value
              }
              value
            }
            defaultType {
              color
              darkColor
              value
            }
          }
        }
      }
    `
  )
  const {
    title,
    description,
    keywords,
    colorsLight,
    colorsDark,
    colorsLightFilters,
    colorsDarkFilters,
    tags,
    defaultType,
  } = site.siteMetadata as SnapsenMeta

  const meta = [
    { name: "description", content: description },
    { name: "keywords", content: keywords.join(", ") },
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no",
    },
  ]
  const typeLabels = tags.find(
    (tag: SnapsenTag) => tag.value == "type"
  )?.labelMap
  typeLabels.push(defaultType)

  return (
    <>
      <Helmet title={title} meta={meta} />
      <GlobalStyle
        colorsLight={colorsLight}
        colorsDark={colorsDark}
        colorsLightFilters={colorsLightFilters}
        colorsDarkFilters={colorsDarkFilters}
        typeLabels={typeLabels}
      />
      <InnerBg>
        <StyledHeader path={path} />
        <Main>{children}</Main>
        <StyledFooter />
      </InnerBg>
    </>
  )
}

export default Layout
